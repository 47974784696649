<template>
  <v-row>
    <v-col>
      <v-card>
        <v-toolbar color="transparent" flat height="55">
          <v-toolbar-title class="text--secondary subtitle-1 font-weight-medium">Importar</v-toolbar-title>
        </v-toolbar>
        <v-divider />
        <v-card-text class="pa-0">
          <v-list class="transparent">
            <v-list-item v-for="d in choices" :key="d.title" three-line style="border-bottom: 1px solid #D8E0EA">
              <v-list-item-content>
                <v-list-item-title class="body-2 text--secondary font-weight-medium">{{d.title}}</v-list-item-title>
                <v-list-item-subtitle>{{d.description}} <v-btn v-if="d.guide" :href="`${$DOCS_BASE}${d.guide}`" target="_blank" text color="primary" :ripple="false">Ver la guía de uso.</v-btn></v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action class="mt-7">
                <v-btn :to="{name: d.view}" text color="primary">Ir a importar<v-icon color="primary" small right>mdi-arrow-right</v-icon></v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-list>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
export default {
  data: () => ({
    choices: [
      {
        view: 'AccountImportCustomers',
        title: 'Clientes',
        description: 'Aprende más sobre la importación de clientes.',
        guide: '/guide/settings/imports#importar-clientes'
      }
    ]
  })
}
</script>